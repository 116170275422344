.sendMsgImg {
  width: 15rem;
  height: 15rem;
}

.row {
  display: flex;
  background-color: rgba(200,214,229,.2);
}

/* Create three equal columns that sits next to each other */
.column {
  margin:auto
}
.sendMsgTitle {
  text-align: center;
  margin-left: -3rem;
  margin-right: -3rem;
  font-family: Titillium Web, sans-serif;
}

.contactUsForm {
  padding: 0 15rem;
}

@media screen and (max-width: 680px) {

  .sendMsgTitle {
    text-align: center;
    margin: auto;
    font-family: Titillium Web, sans-serif;
  }

  .sendMsgTitle h3 {
    font-size: 1.75rem;
  }

  .sendMsgImg {
    width: 7rem;
    height: 7rem;
  }
}
