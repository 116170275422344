.headerBkg {
  background: rgb(235,235,241);
  background: linear-gradient(0deg, rgba(235,235,241,1) 0%, rgba(64,126,195,1) 100%);
}

.introduction {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0 auto;
}

.aviHeader {
  background-size: 24rem 31rem;
  margin: 0;
  background-image: url(../../images/kevin_final.png);
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  height: 28rem;
  width: 30rem;
}

.introInfo {
  text-align: center;
  margin: auto 0 auto 0;
  font-family: Titillium Web, sans-serif;
  width: 30rem;
}

.introInfo h3 {
  line-height: 0.1rem;
}

.introInfo h4 {
  line-height: 0.1rem;
  letter-spacing: 0.35em;
}

.links {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: center;
}

.menuItems {
  text-align: center;
  font-size: 1.75rem
}

.emailIcon {
  font-size: large;
}

.headerLinks {
  font-weight: 800;
  font-size: 1.75rem;
  color: #407bbc;
  text-shadow: 0 2px #d2cfcf;
}

.resumeLink{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  text-shadow:
    1px 0px 1px #c4c4c4, 0px 1px 1px #e7e7e7;
}

.resumeLink button{
  color: white;
  background-color: #074e76;
  font-size: 15px;
  border-radius: 10px;
  margin-left: .25rem;
  margin-right: .25rem;
  box-shadow: 0 5px #999;
  cursor: pointer;
}


.resumeLink button:hover {
  background-color: #0f81ef;
}

.resumeLink button:active {
  background-color: #0f81ef;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}


@media screen and (max-width: 680px) {
  .introduction {
    flex-direction: column;
  }
  .aviHeader {
    width: 100%;
    margin: 0;
  }
  .menuItems {
    margin-top: .7rem;
  }
}
