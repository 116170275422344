.unstaggered{
  display:none;
}

.staggered{
  display: block;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 150px 0 150px;
}

.container p {
  align-self: center;
  font-family: Titillium Web, sans-serif;
  font-size: 1.75rem;
  font-weight: 600;
  text-shadow: 0 0 30px rgba(37, 108, 255, 1);
}

.aboutImg {
  flex: 0 0 50px;
  margin-right: 20px;
  height: 200px;
}

@media screen and (max-width: 992px) {
  .staggered {
    display:none;
  }
  .unstaggered {
    display:block;
  }
  .container {
    flex-direction: column;
    margin: 10px;
  }
  .aboutImg {
    margin: 0;
    height: 125px;
  }
  .container p {
    text-align: center;
    font-size: 1.25rem;
    margin: 5px 75px;
  }
}

@media screen and (max-width: 600px) {
  .staggered {
    display:none;
  }
  .unstaggered {
    display:block;
  }
  .container {
    flex-direction: column;
    margin: 10px;
  }
  .aboutImg {
    margin: 0;
    height: 125px;
  }
  .container p {
    text-align: center;
    font-size: 1.25rem;
    margin: 5px 5px;
  }
}
