ul > ul > li {
  list-style-type: square;
}

.descriptionTitle {
  font-size: 20px;
  font-family: "Quicksand", Arial, sans-serif;
  font-weight: 500;
}

.descriptionWebsite {
  padding-left: 15px;
  font-size: 1.5em;
  font-style: italic;
}

.descriptionBackground {
  background: rgb(235,235,241);
  background: linear-gradient(180deg, rgba(235,235,241,1) 0%, rgb(207, 208, 236) 100%);
}

.panelHeadingBottomBorder {
  border-bottom: 1px solid LightGrey !important;
}



