.home {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-family: Comic Sans MS, serif;
  font-size: 2rem;
}

.quote {
  margin-bottom: 3rem;
}

.music {
  margin-bottom: 2rem;
  font-weight: 550;
  width: 50%
}

@media screen and (max-width: 680px) {
  .home{
    font-size: 1.5rem
  }
  .music {
    width: 100%
  }
}
